import { Stack, useScreenSize } from "flicket-ui";
import { FilterConnectedDateRangeSelect } from "~features/reports/components/ReportOptionSelects";
import { DesktopExtraFilters } from "./ExtraFilters";

export const ReportingFilters = () => {
  const screenSizes = useScreenSize();
  const isMobile = screenSizes.isTabletPortraitDown;

  return (
    <Stack gap={1}>
      <FilterConnectedDateRangeSelect
        maxWidth={isMobile ? "100%" : 380}
        height={"48px"}
      />

      {!isMobile && <DesktopExtraFilters />}
    </Stack>
  );
};
