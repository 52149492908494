import {
  EventReportingMarketingOnsaleCompetitionDocument,
  EventReportingMarketingOnsalePromoterDocument,
  EventReportingMarketingRegistrationOverviewDocument,
  MarketingOnsaleCompetitionDocument,
  MarketingOnsalePromoterDocument,
  MarketingRegistrationCompetitionDocument,
  MarketingRegistrationOverviewDocument,
  MarketingRegistrationPromoterDocument,
  PointReportingFilterSource,
  ReportingFilterSource,
  ReportingFinancialSalesBreakdownSource,
} from "~graphql/typed-document-nodes";
import { useQuery } from "~hooks/useQuery";
import useLayoutContext, {
  isEventLayoutContext,
  isMembershipLayoutContext,
  isSeasonLayoutContext,
  ReportingLayoutContext,
} from "./useLayoutContext";
import { OrderChannel } from "~graphql/sdk";
import useFilterContext from "./useFiltersContext";
import { PRIMARY_NAVIGATION_KEYS } from "../navigation/primary.config";
import { useOrganization, useUser } from "~hooks";

export const convertSource = <
  T extends PointReportingFilterSource | ReportingFinancialSalesBreakdownSource
>(
  source?: ReportingFilterSource
): T => {
  switch (source) {
    case ReportingFilterSource.Event:
      return "EVENT" as T;
    case ReportingFilterSource.Membership:
      return "MEMBERSHIP" as T;
    default:
      return "EVENT" as T;
  }
};

export const useReportParams = () => {
  const { sourceId, source, seasonId } = useReportingItem();
  const { dateRange, getSubmissionFilterValue } = useFilterContext();
  return {
    sourceId,
    startDate: dateRange.startDate.toISOString(),
    endDate: dateRange.endDate.toISOString(),
    releaseId: getSubmissionFilterValue("releaseId"),
    channel: getSubmissionFilterValue("channel") as OrderChannel | undefined,
    source,
    seasonId,
    release: getSubmissionFilterValue("releaseId"),
  };
};

export const useReportingItem = (): {
  context: ReportingLayoutContext;
  eventId: string;
  source: ReportingFilterSource;
  membershipId: string;
  sourceId: string;
  orgId: string;
  orgName: string;
  hasInsideFee: boolean;
  hasRegistration: boolean;
  isSeated: boolean;
  hasOnsaleCompetition: boolean;
  hasRegistrationCompetition: boolean;
  seasonId: string | undefined | null;
} => {
  const context = useLayoutContext();
  const eventId = isEventLayoutContext(context) ? context.eventId : null;

  const membershipId = isMembershipLayoutContext(context)
    ? context.membershipId
    : null;

  const seasonId = isSeasonLayoutContext(context) ? context.seasonId : null;

  let sourceId: string;
  let source: ReportingFilterSource;
  switch (context.type) {
    case PRIMARY_NAVIGATION_KEYS.EVENTS:
      sourceId = eventId;
      source = ReportingFilterSource.Event;
      break;
    case PRIMARY_NAVIGATION_KEYS.MEMBERSHIPS:
      sourceId = membershipId;
      source = ReportingFilterSource.Membership;
      break;
    case PRIMARY_NAVIGATION_KEYS.SEASONS:
      sourceId = null;
      source =
        context.seasonSource === ReportingFinancialSalesBreakdownSource.Event
          ? ReportingFilterSource.Event
          : ReportingFilterSource.Membership;
      break;
    default:
      sourceId = null;
      source = ReportingFilterSource.Event;
      break;
  }

  const { organization } = useOrganization();

  return {
    context,
    eventId,
    source,
    membershipId,
    sourceId,
    orgId: organization.id,
    orgName: organization.name,
    hasInsideFee:
      (context.type === PRIMARY_NAVIGATION_KEYS.EVENTS &&
        context.eventInformation?.hasInsideFee) ??
      false,
    hasRegistration: isEventLayoutContext(context)
      ? context.eventInformation?.hasRegistration
      : false,
    hasOnsaleCompetition: isEventLayoutContext(context)
      ? context.eventInformation?.hasOnsaleCompetition
      : false,
    isSeated: isEventLayoutContext(context)
      ? context.eventInformation?.isSeated
      : false,
    hasRegistrationCompetition: isEventLayoutContext(context)
      ? context.eventInformation?.hasRegistrationCompetition
      : false,
    seasonId,
  };
};

export const useMarketingRegistrationOverview = () => {
  const params = useReportParams();
  const { userRoles } = useUser();

  const { data, error, isLoading } = useQuery(
    userRoles.EventReporting
      ? EventReportingMarketingRegistrationOverviewDocument
      : MarketingRegistrationOverviewDocument,
    {
      sourceId: params.sourceId,
    }
  );
  return { data, error, isLoading };
};

export const useMarketingRegistrationCompetition = () => {
  const params = useReportParams();

  const { data, error, isLoading } = useQuery(
    MarketingRegistrationCompetitionDocument,
    {
      sourceId: params.sourceId,
    }
  );
  return { data, error, isLoading };
};

export const useMarketingOnsaleCompetition = () => {
  const params = useReportParams();
  const { userRoles } = useUser();

  const { data, error, isLoading } = useQuery(
    userRoles.EventReporting
      ? EventReportingMarketingOnsaleCompetitionDocument
      : MarketingOnsaleCompetitionDocument,
    {
      sourceId: params.sourceId,
    }
  );
  return { data, error, isLoading };
};

export const useMarketingRegistrationPromoter = () => {
  const params = useReportParams();
  const { data, error, isLoading } = useQuery(
    MarketingRegistrationPromoterDocument,
    {
      sourceId: params.sourceId,
    }
  );
  return { data, error, isLoading };
};

export const useMarketingOnsalePromoter = () => {
  const params = useReportParams();
  const { userRoles } = useUser();

  const { data, error, isLoading } = useQuery(
    userRoles.EventReporting
      ? EventReportingMarketingOnsalePromoterDocument
      : MarketingOnsalePromoterDocument,
    {
      sourceId: params.sourceId,
    }
  );

  return { data, error, isLoading };
};
